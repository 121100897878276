import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import './styles/pre-login_light_theme.css';


const PreLogin = () => {

    let navigate = useNavigate(); 

    const loginHandler = () => {
        navigate('/login-home');
    }

    return (
        <div id="generator-container">
            <button id="theme-switch">
                <span className="icon">&#9728;</span>
            </button>
            <br />

            <h1>AI-kk Image Generator pre-Login</h1>

            <br />
            <label htmlFor="promptInput">Enter your prompt:</label>
            <input type="text" id="promptInput" placeholder="E.g., 'A futuristic tech city'" />

            <button id="login" onClick={loginHandler}>
                Login
            </button>

            <div id="outputImage"></div>
        </div>
    );
};

export default PreLogin;
