import React, { useState, CSSProperties } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './styles/login-home_light_theme.css';
import axios from 'axios';
import HashLoader from 'react-spinners/HashLoader'


const override: CSSProperties = {
    display: "block",
    margin: "0 auto"
}

const grayOutButton = () => {
    document.getElementById('generateImage').disabled = true;
    document.getElementById('generateImage').style.backgroundColor = '#ccc';
    document.getElementById('generateImage').style.color = '#666';
}

const enableButton = () => {
    document.getElementById('generateImage').disabled = false;
    document.getElementById('generateImage').style.backgroundColor = '#3498db';
    document.getElementById('generateImage').style.color = '#ffffff';
}



const LoginHome = () => {    
    
    const [showImage, setShowImage] = useState(false);
    const [outImage, setOutImage] = useState('');
    const [loading, setLoading] = useState(false);

    const generateImageHandler = async () => {
        try {
            
            if (showImage) {
                setOutImage('')
            }

            grayOutButton();

            setShowImage(true);
            setLoading(true);

            const prompt = document.getElementById('promptInput').value;
            console.log(prompt)
            const api = 'https://2m5sxvd0n9.execute-api.us-west-2.amazonaws.com/beta/ai-kk-image_generator';
            const testApi = 'https://sngxbymwuj.execute-api.us-west-2.amazonaws.com/beta/ai-kk-test-function'
            const data = { prompt: prompt };
            
            const postData = async () => {
                try {
                    console.log("sending to openai API");
            
                    const response = await axios.post(api, data);
            
                    setLoading(false);
                    console.log("success");
                    console.log(response);
            
                    const jsonString = response.data.body;
                    const jsonResponse = JSON.parse(jsonString);
            
                    console.log(jsonResponse.image_url);
                    setOutImage(jsonResponse.image_url);
                    enableButton();
                } catch (error) {
                    console.log(error);
                }
            };
            
            // Call the async function
            postData();

            // axios
            //     .post(api, data)
            //     .then(console.log("sending to openai API"))
            //     .then((response) => {
            //         setLoading(false)
            //         console.log("success");
            //         console.log(response);

            //         const jsonString = response.data.body;
            //         const jsonResponse = JSON.parse(jsonString);
                    
            //         // setOutputImage(response.data.image_url);
            //         console.log(jsonResponse.image_url);
            //         setOutImage(jsonResponse.image_url);
            //         enableButton();
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //     });
        } catch (error) {
            console.error('Error generating image:', error);
        }
    };

    return (
        <Authenticator>
            {({ signOut }) => (
                <div id="homePage">
                    <div id="generator-container">
                        <button id="signOut" onClick={signOut}>
                            Sign Out
                        </button>
                        <br />
                
                        <button id="theme-switch">
                            <span className="icon">&#9728;</span>
                        </button>
                
                        <h1>Welcome to AI-kk Image generator</h1>
                
                        <br />
                        <label htmlFor="promptInput">Enter your prompt:</label>
                        <input type="text" id="promptInput" placeholder="E.g., 'A futuristic tech city'" />
                
                        <button id="generateImage" onClick={generateImageHandler}>
                            Generate Image
                        </button>
                    </div>

                    <div id="imagePlace">
                        {showImage && <img src={outImage}></img>}
                        <HashLoader id="loader" loading={loading} size={60} color="blue" cssOverride={override}></HashLoader>

                    </div>

                </div>
        
                
            )}
        </Authenticator>
    );
};

export default LoginHome;
