import './App.css';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import PreLogin from './pages/pre-login'; // Ensure the correct import statement
import LoginHome from './pages/login-home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PreLogin />} />
        <Route path="/login-home" element={<LoginHome />} />
      </Routes>
    </Router>
  );
}

export default App;
